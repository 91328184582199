.--snackbar-position--active {
    top: 20px;
    right: 20px;
    transition: .4s ease all;
}

.--snackbar {
    padding: .5rem;
    max-width: 60%;
    border-radius: 10px;
}


.--snackbar-success {
    color: var(--success);
    border: 2px solid var(--success);
    background-color: var(--success-bg);
}

.--snackbar-warning {
    color: var(--warning);
    border: 2px solid var(--warning);
    background-color: var(--warning-bg);
}

.--snackbar-info {
    color: var(--info);
    border: 2px solid var(--info);
    background-color: var(--info-bg);
}

.--snackbar-danger {
    color: var(--danger);
    border: 2px solid var(--danger);
    background-color: var(--danger-bg);
}