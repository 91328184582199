.--head-back {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
}

.--head-back .--btn-back {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    place-items: center;
}

.--content-qr {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.--content-qr .--box-qr {
    display: grid;
    width: 250px;
    height: 250px;
    border-radius: 10px;
    place-items: center;
    background-color: var(--white50);
}

.--content-qr .--box-qr canvas {
    width: 200px !important;
    height: 200px !important;
}

.--content-qr .--box-qr .--nme-qr {
    text-align: center;
}
.--content-qr .--box-qr .--nme-qr p {
    font-size: .8rem;
    color: #888888;
}