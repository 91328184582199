.--title-cards {
    margin-bottom: .5rem;
}

.--title-cards span {
    text-transform: capitalize;
}

.--lst-cards {
    width: 100%;
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    padding: 1rem 0rem;
}

.--lst-cards::-webkit-scrollbar {
    display: none;
}