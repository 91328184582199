.--menu-content {
    position: absolute;
    display: none;
    left: 0;
    top: 150px;
    width: 100%;
    transition: .4s ease all;
    height: calc(100dvh - 150px);
    background-color: var(--primary);
    border-top: 1px solid #FFFFFF;
}

.--menu-content--active {
    display: block;
    transition: .4s ease all;
}


.--menu-content .--menu-content-A {
    width: 100%;
    height: calc(100% - 80px);
}

.--menu-content .--menu-content-B {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
}

.--menu-content .--menu-content-B .--btn-logout {
    width: 80%;
    height: 40px;
    margin: auto;
    font-size: .9rem;
    font-weight: 500;
    border-radius: 100px;
    color: var(--primary);
    background-color: var(--white10);
}