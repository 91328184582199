.--card-birthday {
    width: 100%;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    min-width: 100%;
    align-items: center;
    border-radius: 10px;
    background-color: var(--white40);
}

.--ico-birthday {
    display: grid;
    width: 50px;
    height: 50px;
    min-width: 50px;
    place-items: center;
    border-radius: 100px;
    background-color: var(--white10);
}