.--btn-create {
    position: fixed;
    display: grid;
    right: 20px;
    bottom: 100px;
    width: 60px;
    height: 60px;
    place-items: center;
    border-radius: 100px;
    background-color: var(--primary);
}

.--btn-create .tabler-icon {
    stroke: var(--white10);
}