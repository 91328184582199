.--header {
    grid-area: header;
    width: 100%;
    height: 150px;
    display: flex;
    gap: 1rem;
    padding: 0rem 1rem;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 0px 20px 20px;
    background-color: var(--primary);
}

.--header-border-none {
    border-radius: 0px;
}

.--header .--row {
    width: 100%;
}

.--header .--row-A {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.--header .--row-A .--btn-head {
    display: grid;
    width: 40px;
    height: 40px;
    background: none;
    place-items: center;
}
.--header .--row-A .--btn-head .--ico-tabler {
    stroke: var(--white10);
}

.--header .--row-B {
    width: 100%;
    color: var(--white10);
}