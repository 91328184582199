.--card-pension {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    border: 1px solid var(--primary);
}

.--card-pension .--col-A {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--card-pension .--col-A .--date {
    text-align: center;
    padding: 1rem .5rem;
    border-radius: 100px;
    background-color: var(--primary);
}

.--card-pension .--col-A .--date span {
    display: block;
    color: var(--white10);
}
.--card-pension .--col-A .--date .--span-day-mont {
    font-size: .7rem;
    text-transform: uppercase;
}
.--card-pension .--col-A .--date .--span-day-numb {
    font-weight: bold;
    font-size: 1.2rem;
}

.--card-pension .--col-A .--text h4 {
    font-size: 1.25rem;
    color: var(--primary);
}
.--card-pension .--col-A .--text span {
    font-size: .8rem;
    color: var(--gray);
}

.--card-pension .--col-B .--price-totally {
    font-weight: bold;
    color: var(--primary);
}