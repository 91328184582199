:root {

    --white10: rgb(242, 240, 245);
    --white20: rgb(242, 240, 245, .2);
    --white30: rgb(242, 240, 245, .3);
    --white40: rgb(242, 240, 245, .4);
    --white50: rgb(242, 240, 245, .5);
    --white60: rgb(242, 240, 245, .6);
    --white70: rgb(242, 240, 245, .7);
    --white80: rgb(242, 240, 245, .8);
    --white90: rgb(242, 240, 245, .9);
    --white100: rgb(242, 240, 245, .10);

    --dark10: rgb(37, 37, 37);
    --dark20: rgb(37, 37, 37, .2);
    --dark30: rgb(37, 37, 37, .3);
    --dark40: rgb(37, 37, 37, .4);
    --dark50: rgb(37, 37, 37, .5);
    --dark60: rgb(37, 37, 37, .6);
    --dark70: rgb(37, 37, 37, .7);
    --dark80: rgb(37, 37, 37, .8);
    --dark90: rgb(37, 37, 37, .9);
    --dark100: rgb(37, 37, 37, .10);

    --gray: #888888;

    --primary: #006A42;
    --primary-bg: rgb(0, 106, 66, .2);

    --danger: #df0000;
    --danger-bg: rgb(240, 0, 0, .2);

    --warning: #ceb600;
    --warning-bg: rgba(206, 185, 0, 0.2);

    --success: #3ace00;
    --success-bg: rgb(58, 206, 0, .2);

    --info: #2196f3;
    --info-bg: rgb(33, 150, 243, .2);

    --font-family: "Montserrat", sans-serif;

}

* {
    margin: 0;
    padding: 0;
    user-select: none;
    box-sizing: border-box;
    font-family: var(--font-family);
}

*:focus {
    outline: none;
}

input, select, textarea {
    border: none;
    outline: none;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

a {
    text-decoration: none;
    color: var(--primary);
}