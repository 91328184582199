.--card-product {
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: var(--white90);
}

.--card-product .--content-product {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.--card-product .--content-product .--image-product {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--card-product .--content-product .--image-product .--prev-product {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: var(--white50);
}

.--card-product .--content-product .--text-product p {
    font-size: .8rem;
    color: #888888;
}

.--card-product .--content-product .--opc-product {
    display: flex;
}

.--card-product .--content-product .--opc-product .--btn-delete,
.--card-product .--content-product .--opc-product .--btn-edit {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    place-items: center;
}
.--card-product .--content-product .--opc-product .--btn-delete .tabler-icon {
    stroke: rgb(200, 0, 0);
}
.--card-product .--content-product .--opc-product .--btn-edit .tabler-icon {
    stroke: rgb(0, 77, 220);
}