.--card-meet {
    width: 300px;
    padding: 1rem;
    min-width: 300px;
    border-radius: 10px;
    background-color: var(--white40);
}

.--card-meet-head {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--card-meet-head .--figure {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 100px;
    background-color: var(--white10);
    border: 1px solid var(--white10);
}

.--card-meet-body {
    width: 100%;
    font-size: .9rem;
    padding: 1rem 0rem;
}

.--card-meet-foot {
    width: 100%;
    display: flex;
    padding-top: .5rem;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--white10);
}

.--card-meet-foot .--ico-date {
    display: flex;
    gap: .4rem;
    font-size: .8rem;
    align-items: center;
}
.--card-meet-foot .--ico-date .tabler-icon {
    width: 18px;
    height: 18px;
    stroke-width: 1.5;
}



.--card-create-meet {
    display: grid;
    width: 80px;
    height: 80px;
    min-width: 80px;
    place-items: center;
    border-radius: 10px;
    background-color: var(--primary-bg);
}
.--card-create-meet .--content-create span {
    display: block;
    text-align: center;
}
.--card-create-meet .--content-create .--span-ico .tabler-icon {
    stroke-width: 1.5;
    stroke: var(--primary);
}
.--card-create-meet .--content-create .--span-txt {
    font-size: .8rem;
    font-weight: 500;
    color: var(--primary);
}


.--card-meet-empty {
    width: 300px;
    padding: 1rem;
    min-width: 300px;
    border-radius: 10px;
    background-color: var(--white40);
}
.--card-meet-empty .--card-meet-head {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--card-meet-empty .--card-meet-head .--figure {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 100px;
    background-color: var(--white10);
    border: 1px solid var(--white10);
}