.--card-psychology {
    width: 300px;
    padding: 1rem;
    min-width: 300px;
    border-radius: 10px;
    background-color: var(--white40);
}

.--card-psychology .--title-tip h4 {
    margin-bottom: .5rem;
}

.--card-psychology .--title-tip p {
    font-size: .8rem;
    color: #888888;
}