.--content-height {
    display: grid;
    width: 100%;
    height: 250px;
    place-items: center;
    border-bottom: 1px solid var(--gray);
}

.--content-height .--box-content {
    text-align: center;
}

.--content-height .--box-content span {
    color: var(--gray);
}
.--content-height .--box-content h1 {
    font-size: 2.5rem;
    margin: 1rem 0rem;
    color: var(--primary);
}

.--banks-confence {
    width: 100%;
    display: flex;
    margin: 1rem 0rem;
    padding: .5rem 0rem;
    align-items: center;
    justify-content: space-between;
}
.--banks-confence span {
    font-size: .8rem;
}


.--totally {
    width: 100%;
    display: flex;
    padding: 1rem;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-bg);
}

.--totally span {
    font-size: .8rem;
    color: var(--primary);
}

.--lst-coutas {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: 1rem 0rem;
    flex-direction: column;
}