.--head-form {
    width: 100%;
    display: flex;
    padding: .5rem 0rem;
    justify-content: flex-start;
}
.--head-form .--btn-back {
    display: grid;
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 10px;
    place-items: center;
}

.--form {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--white90);
}

.--form .--form-group {
    margin-bottom: 1rem;
}

.--form .--form-group label {
    display: block;
    font-size: .8rem;
    margin-bottom: 1rem;
}
.--form .--form-group input,
.--form .--form-group select {
    width: 100%;
    height: 50px;
    padding: 0rem 1rem;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.--form .--form-group button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    color: var(--white10);
    background-color: var(--primary);
}