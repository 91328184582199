.--card-course {
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: var(--white10);
}

.--card-course-image {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.--card-course-text {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.--card-course-text p {
    font-size: .9rem;
}

.--card-course-text .--btn-next {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    place-items: center;
}