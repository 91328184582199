.--card-product {
    padding: .5rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: var(--white90);
}

.--card-product .--content-product {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.--card-product .--content-product .--image-product {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--card-product .--content-product .--image-product .--prev-product {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 10px;
    background-color: var(--white50);
}

.--card-product .--content-product .--text-product h4 {
    text-wrap: wrap;
}
.--card-product .--content-product .--text-product p {
    font-size: .8rem;
    color: #888888;
    text-wrap: nowrap;
}

.--card-product .--content-product .--opc-product {
    display: flex;
}

.--card-product .--content-product .--opc-product .--btn-delete,
.--card-product .--content-product .--opc-product .--btn-edit {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    place-items: center;
}
.--card-product .--content-product .--opc-product .--btn-delete .tabler-icon {
    stroke: rgb(200, 0, 0);
}
.--card-product .--content-product .--opc-product .--btn-edit .tabler-icon {
    stroke: rgb(0, 77, 220);
}



.--card-product-reserv {
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: var(--white90);
}

.--card-product-reserv--ok {
    background-color: var(--primary-bg);
}

.--card-product-reserv--active {
    border: 2px solid var(--primary);
}

.--content-product-reserv {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.--content-product-reserv .--col {
    display: flex;gap: .5rem;
    align-items: center;
}

.--content-product-reserv .--col .--figure {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 10px;
}

.--content-product-reserv .--col .--figure img {
    width: 100%;
    height: 100%;
}

.--card-product-reserv--ok .--col .--data {
    background: none;
}

.--content-product-reserv .--col .--sizes {
    display: flex;
}

.--content-product-reserv .--col .--sizes .--btn-add,
.--content-product-reserv .--col .--sizes .--btn-dress,
.--content-product-reserv .--col .--sizes .--btn-min {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: none;
    place-items: center;
}