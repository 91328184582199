.--main {
    grid-area: main;
    width: 100%;
    overflow-y: auto;
    padding: 1rem 1rem 80px 1rem;
    height: calc(100dvh - 150px);
}

.--main::-webkit-scrollbar {
    display: none;
}