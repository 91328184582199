.--portada {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    margin-bottom: 60px;
    background-color: var(--primary);
}
.--avatar {
    position: absolute;
    left: 10px;
    top: 100px;
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 100px;
    border: 4px solid #FFFFFF;
    background-color: var(--primary);
}

.--info-name {
    width: 100%;
    margin-bottom: 1rem;
}

.--info-name span {
    font-size: .8rem;
    color: var(--gray);
}

.--options {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
}

.--options .--btn-opt {
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
    background-color: var(--white10);
}
.--options .--btn-opt .tabler-icon {
    stroke-width: 1.5;
}

.--data {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--white10);
}

.--data .--tit-data {
    font-weight: 500;
    margin-bottom: 1rem;
}

.--data .--wrap-dt {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
}

.--data .--wrap-dt .--ico {
    display: grid;
    width: 30px;
    height: 30px;
    min-width: 30px;
    place-items: center;
}
.--data .--wrap-dt .--ico .tabler-icon {
    stroke-width: 1.5;
}

.--edit-profile {
    width: 100%;
    height: 50px;
    margin: 1rem 0rem;
    border-radius: 10px;
    color: var(--primary);
    background-color: var(--primary-bg);
}