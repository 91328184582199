.--card-schedule-day {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.--card-schedule-day .--col-schedule-day .--day {
    display: grid;
    width: 60px;
    height: 60px;
    min-height: 60px;
    font-weight: bold;
    place-items: center;
    border-radius: 100px;
    color: var(--primary);
    background-color: var(--primary-bg);
}

.--card-schedule-day .--col-schedule-day .--day--active {
    color: #FFFFFF;
    background-color: var(--primary);
}

.--card-schedule-day .--col-schedule-courses {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.--card-schedule-day .--col-schedule-courses .--schedule-item {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    color: var(--primary);
    background-color: var(--primary-bg);
}

.--card-schedule-day .--col-schedule-courses .--schedule-item span {
    font-size: .8rem;
    color: var(--primary);
}

.--card-schedule-day .--col-schedule-courses .--schedule-item h3 {
    margin: 1rem 0rem;
    font-size: 1.2rem;
}

.--card-schedule-day .--col-schedule-courses .--schedule-item--active {
    color: var(--white10);
    background-color: var(--primary);
}

.--card-schedule-day .--col-schedule-courses .--schedule-item--active span {
    color: var(--white10);
}