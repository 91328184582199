/* POSITIONS */

.--relative {
    position: relative;
}
.--absolute {
    position: absolute;
}
.--fixed {
    position: fixed;
}

/* POSITIONS */

/* DISPLAYS */

.--block {
    display: block;
}
.--grid {
    display: grid;
}
.--flex {
    display: flex;
}

.--grid-centered {
    place-items: center;
}

/* DISPLAYS */

/* SIZES */

.--sw-10 {
    width: 10px;
}
.--sw-20 {
    width: 20px;
}
.--sw-30 {
    width: 40px;
}
.--sw-40 {
    width: 40px;
}
.--sw-50 {
    width: 50px;
}
.--sw-60 {
    width: 60px;
}
.--sw-70 {
    width: 70px;
}
.--sw-80 {
    width: 80px;
}
.--sw-90 {
    width: 90px;
}
.--sw-100 {
    width: 100%;
}

.--sh-10 {
    height: 10px;
}
.--sh-20 {
    height: 20px;
}
.--sh-30 {
    height: 40px;
}
.--sh-40 {
    height: 40px;
}
.--sh-50 {
    height: 50px;
}
.--sh-60 {
    height: 60px;
}
.--sh-70 {
    height: 70px;
}
.--sh-80 {
    height: 80px;
}
.--sh-90 {
    height: 90px;
}
.--sh-100 {
    height: 100%;
}

.--sbr-10 {
    border-radius: 10px;
}
.--sbr-20 {
    border-radius: 20px;
}
.--sbr-30 {
    border-radius: 40px;
}
.--sbr-40 {
    border-radius: 40px;
}
.--sbr-50 {
    border-radius: 50px;
}
.--sbr-60 {
    border-radius: 60px;
}
.--sbr-70 {
    border-radius: 70px;
}
.--sbr-80 {
    border-radius: 80px;
}
.--sbr-90 {
    border-radius: 90px;
}
.--sbr-100 {
    border-radius: 100%;
}

/* SIZES */

/* COLORS */

.--txt-primary {
    color: var(--primary);
}

.--txt-secondary {
    color: var(--gray);
}

/* COLORS */

/* ENTRYS */

.--entry-block {
    width: 100%;
}

/* ENTRYS */

/* BUTTONS */

.--btn-block {
    width: 100%;
}

.--btn-primary {
    color: var(--white10);
    background-color: var(--primary);
}

/* BUTTONS */


/* ICONS */

.--ico-tabler {
    width: 24px;
    height: 24px;
    stroke-width: 1.5;
}

/* ICONS */

/* CURSOR */

.--pointer {
    cursor: pointer;
}

/* CURSOR */