.--footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    display: grid;
    padding: 0em 1em;
    place-items: center;
}

.--content-footer {
    width: 100%;
    height: 60px;
    display: flex;
    padding: 0em .5em;
    align-items: center;
    border-radius: 100px;
    justify-content: space-between;
    background-color: var(--dark10);
}

.--content-footer .--btn-nav {
    display: grid;
    width: 40px;
    height: 40px;
    background: none;
    place-items: center;
    border-radius: 100px;
    transition: .4s ease all;
}

.--content-footer .--btn-nav .--txt {
    display: none;
}

.--content-footer .--btn-nav .--ico-tabler {
    stroke: var(--white50);
}

.--content-footer .--btn-nav--active {
    display: flex;
    width: auto;
    gap: .5rem;
    padding: 0rem .35rem;
    align-items: center;
    transition: .4s ease all;
    background-color: var(--white10);
}

.--content-footer .--btn-nav--active .--txt {
    display: block;
    font-size: .8rem;
    font-weight: 500;
}

.--content-footer .--btn-nav--active .--ico-tabler {
    stroke: var(--dark10);
}