.--app-l0g1n {
    width: 100dvw;
    height: 100dvh;
}

.--c9ol-l0go {
    width: 100%;
    height: 250px;
    background-color: var(--primary);
}

.--c9ol-l0go .--figure {
    width: 150px;
    height: 150px;
}

.--c9ol-f0rm {
    width: 100%;
    display: grid;
    place-items: center;
    height: calc(100dvh - 250px);
}


.--c9ol-f0rm .--card {
    width: 90%;
    margin: auto;
}

.--c9ol-f0rm .--card h1 {
    text-align: center;
    margin-bottom: .5rem;
}
.--c9ol-f0rm .--card p {
    font-size: .9rem;
    text-align: center;
    color: var(--gray);
}

.--c9ol-f0rm .--card .--form {
    width: 100%;
    margin-top: 1rem;
}

.--c9ol-f0rm .--card .--form .--form-group {
    margin: 1rem 0rem;
}

.--c9ol-f0rm .--card .--form .--form-group .--form-control {
    width: 100%;
}

.--c9ol-f0rm .--card .--form .--form-group .--form-control .--entry {
    padding: 0em 50px;
    background-color: #FFFFFF;
}

.--c9ol-f0rm .--card .--form .--form-group .--form-control .--ico-form-left {
    top: 0;
    left: 0;
}
.--c9ol-f0rm .--card .--form .--form-group .--form-control .--ico-form-right {
    top: 0;
    right: 0;
}

.--c9ol-f0rm .--card .--form .--form-group .--a-tool {
    display: inline-block;
    margin-top: 1rem;
    font-size: .9rem;
    font-weight: 500;
}