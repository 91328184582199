.--card-assitant {
    width: 100%;
    padding: 1rem;
    text-align: center;
    border-radius: 10px;
    background-color: var(--white40);
}

.--card-assitant--active {
    color: var(--primary);
    border: 2px solid var(--primary);
    background-color: var(--primary-bg);
}

.--card-assitant .--tt- .--day-num {
    font-size: 1.5rem;
    font-weight: 500;
}

.--card-assitant .--tt- .--day-txt {
    font-size: .8rem;
    text-transform: uppercase;
}